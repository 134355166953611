import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'Components/button';
import loc from 'Components/languages';

class ButtonAdditionalOptions extends PureComponent {
	render() {
		const { specialOptionsInfo, padding, isDisabled, onClick } = this.props;
		if (specialOptionsInfo && specialOptionsInfo.data && specialOptionsInfo.data.length > 0) {
			return (
				<Button
					text={ loc.additionalOptions }
					theme="default"
					className="uk-margin-medium-right"
					padding={ padding }
					disabled={ isDisabled }
					onClick={ onClick }
				/>
			);
		}
		return null;
	}
}

ButtonAdditionalOptions.propTypes = {
	padding: PropTypes.string,
	isDisabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

ButtonAdditionalOptions.defaultProps = {
	padding: 'medium',
};

export default connect(
	(state) => ({
		specialOptionsInfo: state.specialOptionsInfo,
	})
)(ButtonAdditionalOptions);