import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import InputText from 'Components/input-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import styles from './PaymentDetailEntry.less';

class PaymentDetailEntry extends PureComponent {
	constructor() {
		super();
		this.state = {
			amount: 0,
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
		this.onRemoveHandler = this.onRemoveHandler.bind(this);
	}

	componentDidMount() {
		const { amount } = this.props;
		this.setState({
			amount: amount || 0,
		});
	}

	componentDidUpdate(prevProps) {
		const amount = this.props.amount;
		const prevAmount = prevProps.amount;
		if (amount !== prevAmount) {
			this.setState({
				amount: amount || 0,
			});
		}
	}

	onChangeHandler(e) {
		let value = e.target.value;
		const dotPosition = value.indexOf('.');
		if (dotPosition !== -1) {
			value = value.substring(0, dotPosition + 3);
		}
		this.setState({
			amount: value,
		}, _.debounce(this.onBlurHandler, 500));
	}

	onBlurHandler() {
		const { amount } = this.state;
		if (amount !== this.props.amount) {
			const { index, onChange } = this.props;
			onChange(index, { amount });
		}
	}

	onRemoveHandler() {
		const { index, onRemove } = this.props;
		onRemove(index);
	}

	render() {
		const { icon, code, couponCode } = this.props;
		const { amount } = this.state;
		return (
			<div className={ classnames(styles.field, 'uk-flex uk-flex-middle uk-width-1-1 uk-margin-bottom') }>
				<div className="uk-width-2-5 uk-text-secondary uk-padding uk-padding-remove-vertical uk-text-right">
					<div className={ classnames('uk-button', 'uk-button-default', styles.button) }>
						{
							code && code !== 'DP' ? (
								icon ? (
									<img src={ `data:image/png;base64,${icon}` } className={ styles.icon } />
								) : (
									<span className="uk-h4 uk-text-secondary">?</span>
								)
							) : (
								<FontAwesomeIcon icon={ faCheck } size="2x" className={ styles.check } />
							)
						}
					</div>
				</div>
				<div className="uk-width-2-5 uk-position-relative">
					<div className="uk-flex uk-flex-between uk-flex-middle">
						$
						<InputText
							type="number"
							value={ amount }
							placeholder="0"
							onChange={ this.onChangeHandler }
							onBlur={ this.onBlurHandler }
						/>
					</div>
					{
						code && (code === 'CP' || code === 'GC') && (
							<div className={ classnames('uk-width-1-1', 'uk-position-right-bottom', 'uk-text-muted', 'uk-text-small', 'uk-text-right', styles.couponCode) }>
								{ couponCode }
							</div>
						)
					}
				</div>
				<div className="uk-width-1-5 uk-padding uk-padding-remove-vertical">
					<div className={ classnames('uk-text-danger', styles.trash) } onClick={ this.onRemoveHandler }>
						<FontAwesomeIcon icon={ faTrashAlt } />
					</div>
				</div>
			</div>
		);
	}
}

PaymentDetailEntry.propTypes = {
	index: PropTypes.number.isRequired,
	icon: PropTypes.string,
	code: PropTypes.string.isRequired,
	couponCode: PropTypes.string,
	amount: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
};

export default PaymentDetailEntry;