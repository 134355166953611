import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRftInfo } from 'Redux/actions';
import Button from 'Components/button';
import loc from 'Components/languages';
import Spinner from 'Components/spinner';
import Pagination from 'Components/pagination';
import LoadingOverlay from './components/LoadingOverlay.jsx';
import OptomTable from './components/OptomTable.jsx';
import OptomListItem from './components/OptomListItem.jsx';
import styles from './PageSalesOrderOptom.less';

class PageSalesOrderOptom extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onSelectOptomRecordHandler = this.onSelectOptomRecordHandler.bind(this);
		this.onClickContinueHandler = this.onClickContinueHandler.bind(this);
		this.renderRecord = this.renderRecord.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		const salesOrder = this.props.salesOrder;
		const prevSalesOrder = prevProps.salesOrder;
		if (salesOrder && (!prevSalesOrder || salesOrder.get('member_code') !== prevSalesOrder.get('member_code'))) {
			this.fetchData();
		}
	}

	fetchData() {
		const { page } = this.state;
		const { salesOrder } = this.props;

		if (!salesOrder.get('member_code')) {
			return;
		}

		let param = {
			payload: {
				page,
				sort_column: 'rft_date',
				sort_direction: 'desc',
				is_current_shop: null,
				mbr_code: salesOrder.get('member_code'),
			},
		};
		this.props.getRftInfo(param);
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onSelectOptomRecordHandler(record) {
		// No change in optom record for Q->R orders or return orders
		const { salesOrder } = this.props;
		if (salesOrder.get('return_transaction_number')) {
			return;
		}

		this.props.onSelectOptomRecord(record.rft_no);
	}

	onClickContinueHandler(e) {
		e.preventDefault();
		const { next, onStepClick } = this.props;
		onStepClick(next);
	}

	renderRecord(record, index) {
		const { salesOrder } = this.props;
		const rftNo = salesOrder.get('rft_info') ? salesOrder.get('rft_info').rft_no : salesOrder.get('rft_no');
		return (
			<OptomListItem
				key={ `page-sales-order-optom-record-${index}` }
				record={ record }
				active={ rftNo && rftNo === record.rft_no }
				onSelect={ this.onSelectOptomRecordHandler }
			/>
		);
	}

	render() {
		const { rftInfoInfo, salesOrder, isDisabled, isLoading } = this.props;
		const optomRecord = salesOrder.get('rft_info');
		const rftDate = (optomRecord && optomRecord.rft_date) ? moment(optomRecord.rft_date) : null;
		return (
			<Fragment>
				<div
					className="stepContainer uk-height-large uk-position-relative"
					data-uk-height-viewport="offset-top: true; offset-bottom: 20px"
				>
					<div className={ styles.selectContainer }>
						<h3 className={ classnames('uk-text-primary uk-text-bold uk-text-uppercase', styles.selectTitle) }>
							{ loc.optomRecord }
						</h3>
						<div className={ classnames('uk-child-width-1-2', styles.selectWrapper) } data-uk-grid>
							<LoadingOverlay className="uk-height-1-1" active={ isLoading }>
								<div className={ classnames('uk-margin-remove uk-text-bold', styles.selectSubtitle) }>
									{ loc.selectRecord }
								</div>
								<div className={ styles.recordsContainer }>
									{
										(rftInfoInfo.isFetching && rftInfoInfo.data.length === 0) ? (
											<Spinner />
										) : (
											<ul className="uk-list uk-list-divider uk-margin-remove-bottom">
												{ salesOrder.get('member_code') && rftInfoInfo.data && rftInfoInfo.data.data && rftInfoInfo.data.data.map(this.renderRecord) }
											</ul>
										)
									}
								</div>
								<Pagination
									data={ rftInfoInfo.data }
									onPageChange={ this.onChangePageHandler }
								/>
							</LoadingOverlay>
							<div className={ classnames('uk-height-1-1 uk-overflow-auto', styles.detailsWrapper) }>
								<div>
									<div className="uk-text-bold uk-margin-remove">
										{ loc.orderNumber }
									</div>
									<div className="uk-text-muted">
										{ optomRecord ? optomRecord.rft_no : '-' }
									</div>
								</div>
								<div className="uk-margin-medium-top">
									<div className="uk-text-bold uk-margin-remove">
										{ loc.date }
									</div>
									<div className="uk-text-muted">
										{ rftDate ? rftDate.format('YYYY-MM-DD HH:mm:ss') : '-' }
									</div>
								</div>
								<div className="uk-margin-medium-top uk-margin-right">
									<div className="uk-text-bold uk-margin-remove">
										{ loc.optometrist }
									</div>
									<div className={ styles.optoCode }>
										{ optomRecord && optomRecord.rft_opto }
									</div>
									<div className={ classnames('uk-text-muted', styles.optoName) }>
										{ optomRecord && optomRecord.rft_opto_name }
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={ styles.tableContainer }>
						<OptomTable
							record={ salesOrder.get('rft_info') }
						/>
					</div>
					<div className="uk-position-bottom-right">
						<Button
							text={ loc.preview }
							theme="primary"
							arrow={ true }
							disabled={ isDisabled }
							onClick={ this.onClickContinueHandler }
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}

PageSalesOrderOptom.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	type: PropTypes.string.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	next: PropTypes.string.isRequired,
	onStepClick: PropTypes.func.isRequired,
	onSelectOptomRecord: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		rftInfoInfo: state.rftInfoInfo,
	}),
	(dispatch) => ({
		getRftInfo: para => dispatch(getRftInfo(para)),
	})
)(PageSalesOrderOptom);