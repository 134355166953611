import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import loc from 'Components/languages';
import { convertFloatToPrice } from 'Helpers/helpers';
import PaymentDetailField from './PaymentDetailField.jsx';
import PaymentDetailEntry from './PaymentDetailEntry.jsx';
import ExchangeRateBox from './ExchangeRateBox.jsx';
import styles from './PaymentDetails.less';

class PaymentDetails extends PureComponent {
	constructor() {
		super();
		this.isQOrder = this.isQOrder.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	isQOrder() {
		const { salesOrder } = this.props;
		return salesOrder.get('doc_type') === 'DP1';
	}

	onSubmitHandler(e) {
		e.preventDefault();
	}

	render() {
		const { salesOrder, paymentMethods, onFocusDepositAmount, onChangeTransactionPayment, onRemoveTransactionPayment } = this.props;
		const isQOrder = this.isQOrder();
		return (
			<div className="payDetContainer uk-child-width-expand">
				<div className="uk-width-5-6">
					<form
						className="uk-form-horizontal"
						onSubmit={ this.onSubmitHandler }
						data-uk-height-viewport="offset-top: true; offset-bottom: 84px"
						data-uk-overflow-auto="selContainer: .stepContainer; selContent: .payDetContainer"
					>
						<h4 className="uk-text-uppercase">&nbsp;</h4>
						<PaymentDetailField
							label={ loc.orderType }
							value={ isQOrder ? 'Q' : 'R' }
						/>
						<PaymentDetailField
							label={ loc.netAmount }
							value={ convertFloatToPrice(salesOrder.get('net_amount')) }
						/>
						{
							isQOrder && (
								<PaymentDetailField
									label={ loc.depositTotal }
									value={ salesOrder.get('deposit_amount') }
									onClick={ onFocusDepositAmount }
								/>
							)
						}
						<PaymentDetailField
							label={ loc.paymentMethods }
							hideValue={ true }
						/>
						<div
							className="uk-flex uk-flex-column uk-flex-top uk-padding-small uk-padding-remove-horizontal"
						>
							{
								salesOrder.get('transaction_payments').map((transactionPayment, index) => (
									<PaymentDetailEntry
										key={ `payment-details-entry-${index}` }
										index={ index }
										code={ transactionPayment.get('pay_code') }
										icon={ paymentMethods.get(transactionPayment.get('pay_code')) && paymentMethods.get(transactionPayment.get('pay_code')).get('pay_icon') }
										amount={ transactionPayment.get('amount') }
										couponCode={ transactionPayment.get('coupon_code') }
										onChange={ onChangeTransactionPayment }
										onRemove={ onRemoveTransactionPayment }
									/>
								))
							}
						</div>
						<PaymentDetailField
							label={ loc.balanceDue }
							value={ convertFloatToPrice(salesOrder.get('remaining_amount')) }
						/>
						<PaymentDetailField
							label={ loc.paymentAmount }
							value={ convertFloatToPrice(salesOrder.get('payment_amount')) }
						/>
						<PaymentDetailField
							label={ loc.change }
							value={ convertFloatToPrice(salesOrder.get('change_amount')) }
						/>

						<div className={ styles.exchangeRateBox }>
							<ExchangeRateBox
								salesOrder={ salesOrder }
							/>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

PaymentDetails.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	paymentMethods: PropTypes.instanceOf(Map).isRequired,
	onFocusDepositAmount: PropTypes.func.isRequired,
	onChangeTransactionPayment: PropTypes.func.isRequired,
	onRemoveTransactionPayment: PropTypes.func.isRequired,
};

export default PaymentDetails;