import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import loc from 'Components/languages';
import styles from './OptomTable.less';

class OptomTable extends PureComponent {
	constructor() {
		super();
		this.renderValue = this.renderValue.bind(this);
	}

	renderValue(field) {
		const { record } = this.props;
		const value = record ? record[field] : '';
		return (
			record === null ? (
				<td />
			) : (
				<td>{ value }</td>
			)
		);
	}

	render() {
		return (
			<table className={ styles.table }>
				<thead>
					<tr>
						<th>{ loc.optomNew }</th>
						<th>{ loc.optomFPD }</th>
						<th>{ loc.optomNPD }</th>
						<th>{ loc.optomSPH }</th>
						<th>{ loc.optomCYL }</th>
						<th>{ loc.optomAXIS }</th>
						<th>{ loc.optomPRISM }</th>
						<th>{ loc.optomBASE }</th>
						<th>{ loc.optomADD }</th>
						<th>{ loc.optomVA }</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>R:</th>
						{ this.renderValue('p_pdd_rr') }
						{ this.renderValue('p_pdn_rr') }
						{ this.renderValue('p_sph_rr') }
						{ this.renderValue('p_cyl_rr') }
						{ this.renderValue('p_axis_rr') }
						{ this.renderValue('p_prism_rr') }
						<td />
						{ this.renderValue('p_add_rr') }
						{ this.renderValue('p_va_rr') }
					</tr>
					<tr>
						<th>L:</th>
						{ this.renderValue('p_pdd_ll') }
						{ this.renderValue('p_pdn_ll') }
						{ this.renderValue('p_sph_ll') }
						{ this.renderValue('p_cyl_ll') }
						{ this.renderValue('p_axis_ll') }
						{ this.renderValue('p_prism_ll') }
						<td />
						{ this.renderValue('p_add_ll') }
						{ this.renderValue('p_va_ll') }
					</tr>
				</tbody>
			</table>
		);
	}
}

OptomTable.propTypes = {
	record: PropTypes.object,
};

export default OptomTable;