import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
	faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import classnames from 'classnames';
import { convertFloatToPrice } from 'Helpers/helpers';
import loc from 'Components/languages';
import Button from 'Components/button';
import Spinner from 'Components/spinner';
import LoadingOverlay from 'Components/loading-overlay';
import Overlay from 'Components/overlay';
import ModalRemark from 'Components/modal/remark';
import { compareJson } from 'Helpers/helpers';
import { getItem, batchGetItems, findGiftCouponByInput } from 'Redux/actions';
import * as cons from 'Redux/constants';
import ButtonAdditionalOptions from '../components/ButtonAdditionOptions.jsx';
import Cart from './components/Cart.jsx';
import styles from './PageSalesOrderCart.less';
import ModalSelectItem from './components/ModalSelectItem.jsx';
import ModalSelectItems from './components/ModalSelectItems.jsx';

class PageSalesOrderCart extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: process.env.DEFAULT_ITEM_CODE || '',
			showOverlay: false,
			onHideOverlay: null,
			showModalSelectItem: false,
			showModalRemark: false,
			showModalSelectItems: false,
		};
		this.keywordRef = React.createRef();
		this.getItemAPIParams = this.getItemAPIParams.bind(this);
		this.addTransactionItem = this.addTransactionItem.bind(this);
		this.validateCoupon = this.validateCoupon.bind(this);
		this.mapTransactionItemToCode = this.mapTransactionItemToCode.bind(this);
		this.isEditable = this.isEditable.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onFocusRemarkHandler = this.onFocusRemarkHandler.bind(this);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.onSelectQOrderHandler = this.onChangeQROrderHandler.bind(this, 'q');
		this.onSelectROrderHandler = this.onChangeQROrderHandler.bind(this, 'r');
		this.onChangeTransactionItemsHandler = this.onChangeTransactionItemsHandler.bind(this);
		this.onShowOverlayHandler = this.onShowOverlayHandler.bind(this);
		this.onHideOverlayHandler = this.onHideOverlayHandler.bind(this);
		this.onToggleModalSelectItemHandler = this.onToggleModalSelectItemHandler.bind(this);
		this.onToggleModalRemarkHandler = this.onToggleModalRemarkHandler.bind(this);
		this.onToggleModalPromotionsHandler = this.onToggleModalPromotionsHandler.bind(this);
		this.onToggleModalSelectItemsHandler = this.onToggleModalSelectItemsHandler.bind(this);
		this.onClickContinueHandler = this.onClickContinueHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const itemInfo = this.props.itemInfo;
		const prevItemInfo = prevProps.itemInfo;
		const giftCouponInfo = this.props.giftCouponInfo;
		const prevGiftCouponInfo = prevProps.giftCouponInfo;
		const itemsInfo = this.props.itemsInfo;
		const prevItemsInfo = prevProps.itemsInfo;
		const isLoading = this.props.isLoading;
		const prevIsLoading = prevProps.isLoading;

		if (!compareJson(itemInfo, prevItemInfo)) {
			if (itemInfo.type === cons.GET_ITEM.SUCCESS && itemInfo.data.total > 0) {
				if (itemInfo.data.total === 1 && itemInfo.data.data[0].is_exact_match) {
					const newItem = itemInfo.data.data[0];
					this.addTransactionItem(newItem);
					this.setState({
						keyword: '',
					});
				} else {
					this.setState({
						showModalSelectItem: true,
					});
				}
			} else if (itemInfo.type === cons.GET_ITEM.SUCCESS || itemInfo.type === cons.GET_ITEM.FAILURE) {
				const { keyword } = this.state;
				if (keyword) {
					this.props.findGiftCouponByInput({
						payload: {
							value: keyword,
						},
					});
				}
			}
		} else if (!compareJson(giftCouponInfo, prevGiftCouponInfo)) {
			switch (giftCouponInfo.type) {
			case cons.FIND_GIFT_COUPON_BY_INPUT.SUCCESS: {
				if (this.validateCoupon(giftCouponInfo.data)) {
					this.props.onAddCouponCode(giftCouponInfo.data.coupon_code);
					this.setState({
						keyword: '',
					});
				}
			}
				break;
			case cons.FIND_GIFT_COUPON_BY_INPUT.FAILURE:
				alert(loc.MSGItemNotFound);
				break;
			}
		}

		if (!compareJson(itemsInfo, prevItemsInfo) && itemsInfo.type === cons.BATCH_GET_ITEMS.SUCCESS) {
			const { onAddTransactionItems } = this.props;
			const transactionItems = itemsInfo.data.data.map((transactionItem) => {
				let ret = new Map(transactionItem);
				ret = ret.merge({
					item_quantity: ret.get('default_qty') || 1,
					discount_percentage: 0,
					discount_reduction: 0,
					is_promotion_generated: false,
					promotion_related_item_code: null,
				});
				return ret;
			});
			onAddTransactionItems(transactionItems);
		}

		if (!isLoading && isLoading !== prevIsLoading) {
			this.keywordRef.current && this.keywordRef.current.focus && this.keywordRef.current.focus();
		}
	}

	getItemAPIParams() {
		const { type, salesOrder } = this.props;
		let param = {
			payload: {
				page: 1,
				sort_direction: 'asc',
				sort_column: 'item_name_en',
			},
		};
		switch (type) {
		case 'salesOrder':
			param.payload.is_active = true;
			param.payload.is_service_memo = false;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'serviceMemo':
			param.payload.is_active = true;
			param.payload.is_service_memo = true;
			param.payload.is_stock_check = salesOrder.get('doc_type') !== 'DP1';
			break;
		case 'shopOrder':
			break;
		}
		return param;
	}

	addTransactionItem(transactionItem) {
		const { onAddTransactionItem } = this.props;
		this.setState({
			showModalSelectItem: false,
			keyword: '',
		});
		transactionItem = new Map(transactionItem);
		transactionItem = transactionItem.merge({
			item_quantity: transactionItem.get('default_qty') || 1,
			discount_percentage: 0,
			discount_reduction: 0,
			is_promotion_generated: false,
			promotion_related_item_code: null,
		});
		onAddTransactionItem(transactionItem);
	}

	validateCoupon(couponResponse) {
		if (couponResponse.is_valid_coupon === false) {
			// alert(loc.MSGCouponInvalid);
			return false;
		}
		if (couponResponse.is_expired_coupon === true) {
			alert(loc.MSGCouponExpired);
			return false;
		}
		if (couponResponse.is_for_current_shop === false) {
			alert(loc.MSGCouponNotForCurrentShop);
			return false;
		}
		return true;
	}

	mapTransactionItemToCode(transactionItem) {
		return transactionItem.get('item_code');
	}

	isEditable() {
		const { salesOrder } = this.props;
		return !!salesOrder.get('doc_type');
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	onFocusRemarkHandler(e) {
		e.target.blur();

		if (!this.isEditable()) {
			return;
		}

		const { showModalRemark } = this.state;
		this.setState({
			showModalRemark: !showModalRemark,
		});
	}

	onSearchHandler(e) {
		e.preventDefault();

		if (!this.isEditable()) {
			return;
		}

		const { keyword } = this.state;
		let param = this.getItemAPIParams();
		param.payload.keyword = keyword;
		this.props.getItem(param);
	}

	onChangeQROrderHandler(type, e) {
		e.preventDefault();
		const { salesOrder } = this.props;
		if (!salesOrder.get('q_transaction_number') && !salesOrder.get('return_transaction_number')) {
			this.props.onChangeQROrder(type);
		}
	}

	onChangeTransactionItemsHandler(selected, unselected) {
		let callback = null;
		let indicesToDelete = [];
		if (selected.size > 0) {
			callback = () => {
				let param = this.getItemAPIParams();
				param.payload.item_codes = selected.toJS();
				this.props.batchGetItems(param);
			};
			if (unselected.size === 0) {
				callback();
			}
		}
		if (unselected.size > 0) {
			const { salesOrder, onRemoveTransactionItems } = this.props;
			salesOrder.get('transaction_items').forEach((transactionItem, index) => {
				if (unselected.includes(transactionItem.get('item_code'))) {
					indicesToDelete.push(index);
				}
			});
			onRemoveTransactionItems(indicesToDelete, callback);
		}
		this.setState({
			showModalSelectItems: false,
		});
	}

	onShowOverlayHandler(callback) {
		this.setState({
			showOverlay: true,
			onHideOverlay: callback
		});
	}

	onHideOverlayHandler() {
		const { onHideOverlay } = this.state;
		this.setState({
			showOverlay: false,
			onHideOverlay: null,
		}, onHideOverlay);
	}

	onToggleModalSelectItemHandler() {
		const { showModalSelectItem } = this.state;
		if (showModalSelectItem) {
			this.setState({
				showModalSelectItem: false,
				keyword: '',
			});
		} else {
			this.setState({
				showModalSelectItem: true,
			});
		}
	}

	onToggleModalRemarkHandler() {
		const { showModalRemark } = this.state;
		this.setState({
			showModalRemark: !showModalRemark,
		});
	}

	onToggleModalPromotionsHandler() {
		this.props.onToggleModalPromotions('promotions');
	}

	onToggleModalSelectItemsHandler() {
		if (!this.isEditable()) {
			return;
		}

		const { showModalSelectItems } = this.state;
		this.setState({
			showModalSelectItems: !showModalSelectItems,
		});
	}

	onClickContinueHandler(e) {
		e.preventDefault();
		const { next, onStepClick } = this.props;
		onStepClick(next);
	}

	render() {
		const { appLanguage, itemInfo, salesOrder, isDisabled, type, isLoading, onSelectLotNo, onChangeRemark, onChangeTransactionItem, onRemoveTransactionItem, onChangeCouponCode, onRemoveCouponCode, onToggleModalSpecialOptions } = this.props;
		const { keyword, showOverlay, showModalSelectItem, showModalRemark, showModalSelectItems } = this.state;
		const isShopOrder = type === 'shopOrder';
		const isEditable = this.isEditable();
		const transactionItemCodeSet = salesOrder.get('transaction_items').map(this.mapTransactionItemToCode).toSet();
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div
						className="stepContainer uk-height-large"
						data-uk-height-viewport="offset-top: true; offset-bottom: 20px"
					>
						<div className="cartWrapper">
							{/* Header */}
							<div className="uk-flex uk-flex-between">
								{
									!isShopOrder ? (
										<div className={ styles.tabs }>
											<ul className="uk-flex-center uk-tab">
												<li className={ classnames(salesOrder.get('doc_type') === 'DP1' && 'uk-active', (salesOrder.get('q_transaction_number') || salesOrder.get('return_transaction_number')) && 'uk-disabled') }>
													<a onClick={ this.onSelectQOrderHandler }>{ loc.qOrder }</a>
												</li>
												<li className={ classnames((salesOrder.get('doc_type') === 'SA1' || salesOrder.get('doc_type') === 'SA3') && 'uk-active') }>
													<a onClick={ this.onSelectROrderHandler }>{ loc.rOrder }</a>
												</li>
											</ul>
										</div>
									) : (
										<div />
									)
								}
								<div>
									<div className={ classnames('uk-inline', styles.spinner) }>
										{
											itemInfo.isFetching && (
												<Spinner />
											)
										}
									</div>
									<div className="uk-inline uk-margin-left">
										<form onSubmit={ this.onSearchHandler } className={ styles.formSearch }>
											<a onClick={ this.onSearchHandler } className={ classnames('uk-form-icon', 'uk-form-icon-flip', !isEditable && styles.disabled) }>
												<FontAwesomeIcon icon={ faSearch } />
											</a>
											<input
												className="uk-input"
												type="text"
												autoFocus
												ref={ this.keywordRef }
												value={ keyword }
												disabled={ isLoading || itemInfo.isFetching || !isEditable }
												onChange={ this.onChangeKeywordHandler }
											/>
										</form>
									</div>
									{
										!isShopOrder && (
											<div className="uk-inline uk-margin-left uk-margin-small-right">
												<a className={ classnames(styles.btnAddServiceItem, !isEditable && styles.disabled) } onClick={ this.onToggleModalSelectItemsHandler }>
													<FontAwesomeIcon icon={ faPlusSquare } size="2x" />
												</a>
											</div>
										)
									}
								</div>
							</div>
							{/* END Header */}
							<div
								data-uk-height-viewport="offset-top: true; offset-bottom: 170px"
								data-uk-overflow-auto="selContainer: .stepContainer; selContent: .cartWrapper"
							>
								<Cart
									transactionItems={ salesOrder.get('transaction_items') }
									couponCodes={ salesOrder.get('coupon_codes') }
									exceptions={ salesOrder.get('cart_exceptions') }
									type={ type }
									isReturn={ !!salesOrder.get('return_transaction_number') }
									onShowOverlay={ this.onShowOverlayHandler }
									onHideOverlay={ this.onHideOverlayHandler }
									onSelectLotNo={ onSelectLotNo }
									onChangeTransactionItem={ onChangeTransactionItem }
									onRemoveTransactionItem={ onRemoveTransactionItem }
									onChangeCouponCode={ onChangeCouponCode }
									onRemoveCouponCode={ onRemoveCouponCode }
								/>
							</div>
							{/* Footer */}
							<div className={ classnames('uk-height-small uk-flex-inline uk-width-1-1 uk-margin-top', styles.footer) }>
								<div className="uk-width-1-3 uk-inline uk-margin-left">
									<span onClick={ this.onFocusRemarkHandler } className={ classnames('uk-position-bottom-right', 'uk-position-small', 'uk-margin-bottom', styles.icon) }>
										<FontAwesomeIcon icon={ faEdit } />
									</span>
									<textarea
										cols="30"
										rows="4"
										className="uk-textarea uk-padding uk-padding-remove-vertical uk-padding-remove-left"
										style={{ resize: 'none' }}
										placeholder={ loc.tapToEditRemark }
										disabled={ isLoading || !isEditable }
										value={ salesOrder.get('remark') || '' }
										readOnly
										onFocus={ this.onFocusRemarkHandler }
									/>
								</div>
								<div className="uk-width-1-3 uk-margin-medium-left uk-position-relative">
									{
										!isShopOrder && (
											<table className="uk-width-1-1">
												<tbody>
													<tr className="uk-child-width-1-2">
														<td>{ loc.retailAmount }:</td>
														<td>{ convertFloatToPrice(salesOrder.get('retail_amount')) }</td>
													</tr>
													<tr>
														<td>{ loc.totalQty }:</td>
														<td>{ salesOrder.get('total_quantity') }</td>
													</tr>
													<tr>
														<td>{ loc.totalDiscount }:</td>
														<td>{ convertFloatToPrice(salesOrder.get('total_discount')) }</td>
													</tr>
												</tbody>
											</table>
										)
									}
									{
										type === 'salesOrder' && (
											<div className="uk-position-bottom-left">
												<Button
													text={ loc.promotions }
													theme="secondary"
													disabled={ isDisabled }
													onClick={ this.onToggleModalPromotionsHandler }
												/>
											</div>
										)
									}
								</div>
								<div className="uk-width-1-3 uk-margin-medium-left uk-position-relative">
									{
										!isShopOrder && (
											<div className="uk-text-primary uk-text-large uk-text-bold">
												<div className="uk-float-left">{ loc.netAmount }:</div>
												<div className="uk-float-right">{ convertFloatToPrice(salesOrder.get('net_amount')) }</div>
											</div>
										)
									}
									<div className="uk-position-bottom-right">
										<ButtonAdditionalOptions
											padding={ appLanguage.language === 'en' ? 'small' : 'medium' }
											disabled={ isDisabled }
											onClick={ onToggleModalSpecialOptions }
										/>
										<Button
											text={ isShopOrder ? loc.confirm : loc.continue }
											theme="primary"
											padding="medium"
											arrow={ true }
											disabled={ isDisabled }
											onClick={ this.onClickContinueHandler }
										/>
									</div>
								</div>
							</div>
							{/* END Footer */}
						</div>
						{/* END .cartWrapper */}
					</div>
				</LoadingOverlay>

				<Overlay
					isOpen={ showOverlay }
					onToggle={ this.onHideOverlayHandler }
				/>

				<ModalSelectItem
					isOpen={ showModalSelectItem }
					onToggle={ this.onToggleModalSelectItemHandler }
					keyword={ keyword }
					type={ type }
					salesOrder={ salesOrder }
					onSelect={ this.addTransactionItem }
				/>

				<ModalRemark
					isOpen={ showModalRemark }
					onToggle={ this.onToggleModalRemarkHandler }
					onChange={ onChangeRemark }
					docType={ salesOrder.get('doc_type') }
					remark={ salesOrder.get('remark') }
					isLoading={ isLoading }
				/>

				{
					!isShopOrder && (
						<ModalSelectItems
							isOpen={ showModalSelectItems }
							isLoading={ isLoading }
							type={ type }
							salesOrder={ salesOrder }
							onToggle={ this.onToggleModalSelectItemsHandler }
							onChange={ this.onChangeTransactionItemsHandler }
							onSelect={ this.addTransactionItem }
							transactionItemCodeSet={ transactionItemCodeSet }
						/>
					)
				}
			</Fragment>
		);
	}
}

PageSalesOrderCart.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	next: PropTypes.string.isRequired,
	onStepClick: PropTypes.func.isRequired,
	onToggleModalSpecialOptions: PropTypes.func.isRequired,
	onChangeQROrder: PropTypes.func,
	onChangeRemark: PropTypes.func.isRequired,
	onSelectLotNo: PropTypes.func.isRequired,
	onToggleModalPromotions: PropTypes.func.isRequired,
	onAddTransactionItem: PropTypes.func.isRequired,
	onAddTransactionItems: PropTypes.func.isRequired,
	onChangeTransactionItem: PropTypes.func.isRequired,
	onRemoveTransactionItems: PropTypes.func.isRequired,
	onAddCouponCode: PropTypes.func.isRequired,
	onChangeCouponCode: PropTypes.func.isRequired,
	onRemoveCouponCode: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		lotItemInfo: state.lotItemInfo,
		itemInfo: state.itemInfo,
		itemsInfo: state.itemsInfo,
		giftCouponInfo: state.giftCouponInfo,
		appLanguage: state.appLanguage,
	}),
	(dispatch) => ({
		getItem: para => dispatch(getItem(para)),
		batchGetItems: para => dispatch(batchGetItems(para)),
		findGiftCouponByInput: para => dispatch(findGiftCouponByInput(para)),
	})
)(PageSalesOrderCart);
