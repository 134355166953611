import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sprintf } from 'sprintf-js';
import loc from 'Components/languages';
import Button from 'Components/button';
import Modal from 'Components/modal';
import styles from './ModalException.less';

class ModalException extends PureComponent {
	constructor() {
		super();
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '500px',
				padding: 0,
			},
		};
		this.getExceptions = this.getExceptions.bind(this);
		this.renderException = this.renderException.bind(this);
	}

	getExceptions() {
		const { page, data } = this.props;
		if (!(data.data && data.data.apiData)) {
			return [];
		}
		const apiData = data.data.apiData;
		let ret = [];
		if (apiData.salesman_exceptions) {
			ret = [...ret, ...apiData.salesman_exceptions];
		}
		if (apiData.cart_exceptions) {
			ret = [...ret, ...apiData.cart_exceptions];
		}
		if (page !== 'salesman' && page !== 'cart') {
			if (apiData.payment_exceptions) {
				ret = [...ret, ...apiData.payment_exceptions];
			}
		}
		return ret;
	}

	renderException(exception, index) {
		let message = loc[exception.errorCode] ? loc[exception.errorCode] : exception.message;
		if (message.includes('%d')) {
			message = sprintf(message, exception.detail);
		}
		return (
			<li key={ `modal-exception-${index}` } className="uk-text-capitalize">
				{ message }
			</li>
		);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		const exceptions = this.getExceptions();
		return (
			<Modal
				title={ loc.errorOccurred }
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<div className={ styles.container }>
					<ul className="uk-list uk-list-bullet">
						{ exceptions.map(this.renderException) }
					</ul>
				</div>
				<div className={ styles.button }>
					<Button
						text={ loc.confirm }
						theme="primary"
						onClick={ onToggle }
					/>
				</div>
			</Modal>
		);
	}
}

ModalException.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

export default connect(
	state => ({
		salesOrderInfo: state.salesOrderInfo,
	})
)(ModalException);