import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'Components/modal';
import Button from 'Components/button';
import loc from 'Components/languages';
import styles from './Modal.less';

class ModalDepositAmount extends PureComponent {
	constructor() {
		super();
		this.state = {
			depositAmount: 0,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '150px',
			},
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		const depositAmount = this.props.depositAmount;
		const prevDepositAmount = prevProps.depositAmount;
		if (
			(isOpen && isOpen !== prevIsOpen) ||
			depositAmount !== prevDepositAmount
		) {
			this.setState({
				depositAmount: depositAmount,
			});
		}
	}

	onChangeHandler(e) {
		let { value } = e.target;
		const dotPosition = value.indexOf('.');
		if (dotPosition !== -1) {
			value = value.substring(0, dotPosition + 3);
		}
		this.setState({
			depositAmount: value,
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { depositAmount } = this.state;
		this.props.onChange(parseFloat(depositAmount));
	}

	onCancelHandler() {
		this.props.onToggle();
	}

	render() {
		const { isOpen, isFirstShown, onToggle } = this.props;
		let { depositAmount } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<form className="uk-form-stacked" onSubmit={ this.onSubmitHandler }>
					<div className="uk-margin">
						<label className={ classnames('uk-form-label', styles.label) }>{ loc.pleaseInputDepositAmount }</label>
						<div className="uk-form-controls">
							<input
								id="depositAmount"
								className={ classnames('uk-input', styles.input) }
								type="number"
								placeholder={ loc.depositTotal }
								value={ depositAmount }
								onChange={ this.onChangeHandler }
							/>
						</div>
					</div>

					<div className="uk-margin-small-top uk-text-center uk-flex">
						{
							isFirstShown ? (
								<Button
									text={ loc.confirm }
									theme="primary"
									type="submit"
									className="uk-width-1-1"
									onClick={ this.onSubmitHandler }
								/>
							) : (
								<Fragment>
									<div className={ classnames('uk-width-1-2', styles.buttonColumnLeft) }>
										<Button
											text={ loc.cancel }
											theme="primary"
											type="button"
											className="uk-width-1-1"
											onClick={ this.onCancelHandler }
										/>
									</div>
									<div className={ classnames('uk-width-1-2', styles.buttonColumnRight) }>
										<Button
											text={ loc.confirm }
											theme="primary"
											type="submit"
											className="uk-width-1-1"
											onClick={ this.onSubmitHandler }
										/>
									</div>
								</Fragment>
							)
						}
					</div>
				</form>
			</Modal>
		);
	}
}

ModalDepositAmount.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isFirstShown: PropTypes.bool,
	depositAmount: PropTypes.number.isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default ModalDepositAmount;