import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { extractDateFromDate } from 'Helpers/helpers';
import styles from './OptomListItem.less';

class OptomListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { record, onSelect } = this.props;
		onSelect(record);
	}

	render() {
		const { record, active } = this.props;
		return (
			<li className={ classnames(styles.container, active && styles.active) } onClick={ this.onClickHandler }>
				<div className={ styles.title }>{ record.rft_no }</div>
				<div className={ styles.desc }>
					{ extractDateFromDate(record.rft_date) } { record.rft_mbr_name } / { record.rft_opto } { record.rft_opto_name }
				</div>
			</li>
		);
	}
}

OptomListItem.propTypes = {
	record: PropTypes.object.isRequired,
	active: PropTypes.bool,
	onSelect: PropTypes.func.isRequired,
};

export default OptomListItem;