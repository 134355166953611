exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3exTL {\n  margin-top: 0 !important;\n}\n._3exTL {\n  padding-left: 20px;\n  padding-right: 20px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  cursor: pointer;\n}\n._3exTL .pWGOt {\n  color: #A89A72;\n  font-size: 18px;\n}\n._3exTL ._1SSpu {\n  color: #A0A0A0;\n  font-size: 12px;\n}\n._3exTL.m218P {\n  background-color: #A89A72;\n}\n._3exTL.m218P .pWGOt,\n._3exTL.m218P ._1SSpu {\n  color: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_3exTL",
	"title": "pWGOt",
	"desc": "_1SSpu",
	"active": "m218P"
};