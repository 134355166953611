exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3m0qD {\n  height: calc(100% - 270px);\n}\n._3m0qD ._3LI2q {\n  height: 33px;\n}\n._3m0qD ._2eOBY {\n  height: calc(100% - 53px);\n}\n._3m0qD ._2EZVW {\n  height: 29px;\n}\n._3m0qD .LVCe6 {\n  height: calc(100% - 54px - 72px);\n  overflow: auto;\n  padding-top: 10px;\n}\n._3MT8D {\n  padding-bottom: 20px;\n}\n._3MT8D ._3leA6,\n._3MT8D .kjxPm {\n  margin-top: 5px;\n}\n.W1HSV {\n  height: 230px;\n  padding-top: 20px;\n}\n", ""]);

// Exports
exports.locals = {
	"selectContainer": "_3m0qD",
	"selectTitle": "_3LI2q",
	"selectWrapper": "_2eOBY",
	"selectSubtitle": "_2EZVW",
	"recordsContainer": "LVCe6",
	"detailsWrapper": "_3MT8D",
	"optoCode": "_3leA6",
	"optoName": "kjxPm",
	"tableContainer": "W1HSV"
};