import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { DeviceUUID } from 'device-uuid';
import { getUserAgent } from 'universal-user-agent';
import _ from 'lodash';
import ProgressBar from './components/ProgressBar.jsx';
import { receiveApi } from 'Helpers/helpers';
import { setDefaultSalesOrderData } from 'Redux/actions_ui';
import {
	updateSalesOrder,
	postApproval,
	confirmSalesOrder,
	holdSalesOrder,
	previewOrder,
	viewOrder,
	printOrder,
} from 'Redux/actions';
import * as cons from 'Redux/constants';
import loc from 'Components/languages';
import PageSalesOrderSalesman from './salesman';
import PageSalesOrderCart from './cart';
import PageSalesOrderPayment from './payment';
import PageSalesOrderOptom from './optom';
import PageSalesOrderPrint from './print';
import ModalPromotions from './components/ModalPromotions.jsx';
import ModalException from './components/ModalException.jsx';
import ModalApproval from '../order-search/components/ModalApproval.jsx';
import ModalSpecialOptions from './components/ModalSpecialOptions.jsx';

class PageSalesOrder extends PureComponent {
	constructor() {
		super();
		this.defaultSalesOrder = {
			salesman_code1: null,
			salesman_name1: null,
			salesman_code2: null,
			salesman_name2: null,
			optometrist_code: null,
			optometrist_name: null,
			member_code: null,
			member: {
				member_code: null,
				member_name: null,
				member_group: null,
				phone: null,
			},
			doc_type: null,
			transaction_items: new List(),
			transaction_payments: new List(),
			remark: '',
			coupon_codes: new List(),
			promotion_ids_selected: new List(),
			special_options: new Map(),
			deposit_amount: 0,
			retail_amount: 0,
			total_discount: 0,
			total_quantity: 0,
			net_amount: 0,
			remaining_amount: 0,
			change_amount: 0,
			payment_amount: 0,
			salesman_exceptions: [],
			cart_exceptions: [],
			payment_exceptions: [],
			q_transaction_number: null,
			return_transaction_number: null,
			hold_transaction_number: null,
			is_service_memo: false,
			is_shop_order: false,
			is_approval_needed: false,
			is_approval_verified: false,
			approval_user_code: null,
			approval_remark: null,
			promotions: {},
			rft_no: null,
			rft_info: null,
			allowed_return_payment_methods: null,
		};
		let salesOrder = new Map(this.defaultSalesOrder);
		if (process.env.DEFAULT_SALESMAN_CODE1) {
			salesOrder = salesOrder.set('salesman_code1', process.env.DEFAULT_SALESMAN_CODE1);
		}
		if (process.env.DEFAULT_SALESMAN_NAME1) {
			salesOrder = salesOrder.set('salesman_name1', process.env.DEFAULT_SALESMAN_NAME1);
		}
		if (process.env.DEFAULT_SALESMAN_CODE2) {
			salesOrder = salesOrder.set('salesman_code2', process.env.DEFAULT_SALESMAN_CODE2);
		}
		if (process.env.DEFAULT_SALESMAN_NAME2) {
			salesOrder = salesOrder.set('salesman_name2', process.env.DEFAULT_SALESMAN_NAME2);
		}
		if (process.env.DEFAULT_OPTOMETRIST_CODE) {
			salesOrder = salesOrder.set('optometrist_code', process.env.DEFAULT_OPTOMETRIST_CODE);
		}
		if (process.env.DEFAULT_OPTOMETRIST_NAME) {
			salesOrder = salesOrder.set('optometrist_name', process.env.DEFAULT_OPTOMETRIST_NAME);
		}
		this.defaultState = {
			salesOrder,
			signature: null,
			next: null,
			showModalPromotions: null,
			showModalException: false,
			showModalApproval: false,
			showModalSpecialOptions: false,
			approvalUserId: null,
			approvalRemark: null,
		};
		this.state = {
			...this.defaultState,
		};
		this.progressBar = [
			'salesman',
			'cart',
			'payment',
			'optom',
			'preview',
			'print',
		];
		this.isLoading = this.isLoading.bind(this);
		this.isDisabled = this.isDisabled.bind(this);
		this.setIsVerified = this.setIsVerified.bind(this);
		this.allowHoldOrder = this.allowHoldOrder.bind(this);
		this.getSalesOrder = this.getSalesOrder.bind(this);
		this.getPage = this.getPage.bind(this);
		this.getSalesOrderApiData = this.getSalesOrderApiData.bind(this);
		this.saveSalesOrder = this.saveSalesOrder.bind(this);
		this.resetSalesOrder = this.resetSalesOrder.bind(this);
		this.holdSalesOrder = this.holdSalesOrder.bind(this);
		this.confirmSalesOrder = _.throttle(this.confirmSalesOrder.bind(this), 5000);
		this.viewOrder = this.viewOrder.bind(this);
		this.onStepClickHandler = this.onStepClickHandler.bind(this);
		this.onSelectSalesmanHandler = this.onSelectSalesmanHandler.bind(this);
		this.onSelectMemberHandler = this.onSelectMemberHandler.bind(this);
		this.onChangeQROrderHandler = this.onChangeQROrderHandler.bind(this);
		this.onChangeRemarkHandler = this.onChangeRemarkHandler.bind(this);
		this.onSelectLotNoHandler = this.onSelectLotNoHandler.bind(this);
		this.onApplyPromotionsHandler = this.onApplyPromotionsHandler.bind(this);
		this.onAddTransactionItemHandler = this.onAddTransactionItemHandler.bind(this);
		this.onAddTransactionItemsHandler = this.onAddTransactionItemsHandler.bind(this);
		this.onChangeTransactionItemHandler = this.onChangeTransactionItemHandler.bind(this);
		this.onRemoveTransactionItemHandler = this.onRemoveTransactionItemHandler.bind(this);
		this.onRemoveTransactionItemsHandler = this.onRemoveTransactionItemsHandler.bind(this);
		this.onAddCouponCodeHandler = this.onAddCouponCodeHandler.bind(this);
		this.onChangeCouponCodeHandler = this.onChangeCouponCodeHandler.bind(this);
		this.onRemoveCouponCodeHandler = this.onRemoveCouponCodeHandler.bind(this);
		this.onChangeDepositAmountHandler = this.onChangeDepositAmountHandler.bind(this);
		this.onAddTransactionPaymentHandler = this.onAddTransactionPaymentHandler.bind(this);
		this.onChangeTransactionPaymentHandler = this.onChangeTransactionPaymentHandler.bind(this);
		this.onRemoveTransactionPaymentHandler = this.onRemoveTransactionPaymentHandler.bind(this);
		this.onChangeSignatureHandler = this.onChangeSignatureHandler.bind(this);
		this.onPrintHandler = this.onPrintHandler.bind(this);
		this.onToggleModalPromotionsHandler = this.onToggleModalPromotionsHandler.bind(this);
		this.onToggleModalExceptionHandler = this.onToggleModalExceptionHandler.bind(this);
		this.onToggleModalApprovalHandler = this.onToggleModalApprovalHandler.bind(this);
		this.onRequestApprovalHandler = this.onRequestApprovalHandler.bind(this);
		this.onSelectOptomRecordHandler = this.onSelectOptomRecordHandler.bind(this);
		this.onToggleModalSpecialOptionsHandler = this.onToggleModalSpecialOptionsHandler.bind(this);
		this.onChangeSpecialOptionsHandler = this.onChangeSpecialOptionsHandler.bind(this);
	}

	componentDidMount() {
		const param = queryString.parse(this.props.location.search);
		const defaultSalesOrderData = this.props.defaultSalesOrderData;
		const salesOrderInfo = this.props.salesOrderInfo;
		const retrieveHoldSalesOrderInfo = this.props.retrieveHoldSalesOrderInfo;

		if (defaultSalesOrderData.data && Object.keys(defaultSalesOrderData.data).length > 0) {
			let { salesOrder } = this.state;
			[
				'salesman_code1',
				'salesman_name1',
				'salesman_code2',
				'salesman_name2',
				'optometrist_code',
				'optometrist_name',
				'member_code',
				'member',
				'return_transaction_number',
			].forEach((field) => {
				salesOrder = salesOrder.set(field, defaultSalesOrderData.data[field]);
			});
			this.setState({
				salesOrder: salesOrder
					.set(
						'doc_type',
						'SA1'
					)
					.set(
						'remark',
						defaultSalesOrderData.data.return_transaction_number
					)
					.set(
						'transaction_items',
						new List(
							defaultSalesOrderData.data.return_items && defaultSalesOrderData.data.return_items.map((transactionItem) => new Map(transactionItem))
						)
					)
				,
			});

			this.props.setDefaultSalesOrderData({ data: {} });
		}

		if (param) {
			switch (param.action) {
			case 'q_to_r':
			case 'deposit_refund':
				switch (salesOrderInfo.type) {
				case cons.CONVERT_Q_To_R.SUCCESS:
				case cons.DEPOSIT_REFUND.SUCCESS: {
					let newState = {
						salesOrder: this.getSalesOrder(salesOrderInfo.data.apiData),
						salesmanDisabled: false,
					};
					if (newState.salesOrder.get('q_transaction_number')) {
						newState.salesmanDisabled = true;
						newState.salesOrder = newState.salesOrder.set('doc_type', 'SA1');
					}
					this.setState(newState);
					break;
				}
				}
				break;
			case 'hold':
				if (retrieveHoldSalesOrderInfo.type === cons.RETRIEVE_HOLD_SALESORDER.SUCCESS) {
					let newState = {
						salesOrder: this.getSalesOrder(retrieveHoldSalesOrderInfo.data),
						salesmanDisabled: false,
					};
					if (newState.salesOrder.get('q_transaction_number')) {
						newState.salesmanDisabled = true;
						newState.salesOrder = newState.salesOrder.set('doc_type', 'SA1');
					}
					this.setState(newState);
				}
				break;
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { next } = this.state;
		let newState = {};
		const salesOrderInfo = this.props.salesOrderInfo;
		const prevSalesOrderInfo = prevProps.salesOrderInfo;
		const approvalInfo = this.props.approvalInfo;
		const prevApprovalInfo = prevProps.approvalInfo;
		const salesOrderConfirmInfo = this.props.salesOrderConfirmInfo;
		const prevSalesOrderConfirmInfo = prevProps.salesOrderConfirmInfo;
		const salesOrderHoldInfo = this.props.salesOrderHoldInfo;
		const prevSalesOrderHoldInfo = prevProps.salesOrderHoldInfo;
		const setItemToCurrentShopInfo = this.props.setItemToCurrentShopInfo;
		const prevSetItemToCurrentShopInfo = prevProps.setItemToCurrentShopInfo;
		const unsetItemFromCurrentShopInfo = this.props.unsetItemFromCurrentShopInfo;
		const prevUnsetItemFromCurrentShopInfo = prevProps.unsetItemFromCurrentShopInfo;
		if (
			receiveApi(salesOrderInfo, prevSalesOrderInfo, cons.UPDATE_SALESORDER) ||
			receiveApi(salesOrderInfo, prevSalesOrderInfo, cons.CONVERT_Q_To_R) ||
			receiveApi(salesOrderInfo, prevSalesOrderInfo, cons.DEPOSIT_REFUND)
		) {
			newState.salesOrder = this.getSalesOrder(salesOrderInfo.data.apiData);
			newState.salesmanDisabled = false;
			if (newState.salesOrder.get('q_transaction_number')) {
				newState.salesmanDisabled = true;
				newState.salesOrder = newState.salesOrder.set('doc_type', 'SA1');
			}
		}

		if (receiveApi(setItemToCurrentShopInfo, prevSetItemToCurrentShopInfo, cons.SET_ITEM_TO_CURRENT_SHOP)) {
			alert(loc.addedToFavorite);
			this.saveSalesOrder();
		}
		if (receiveApi(unsetItemFromCurrentShopInfo, prevUnsetItemFromCurrentShopInfo, cons.UNSET_ITEM_FROM_CURRENT_SHOP)) {
			alert(loc.removedFromFavorite);
			this.saveSalesOrder();
		}

		if (salesOrderInfo && salesOrderInfo !== prevSalesOrderInfo) {
			if (salesOrderInfo.type === cons.UPDATE_SALESORDER.SUCCESS) {
				if (
					salesOrderInfo.data &&
					salesOrderInfo.data.apiData &&
					(
						(
							salesOrderInfo.data.apiData.salesman_exceptions &&
							salesOrderInfo.data.apiData.salesman_exceptions.length > 0
						) || (
							salesOrderInfo.data.apiData.cart_exceptions &&
							salesOrderInfo.data.apiData.cart_exceptions.length > 0
						)
					)
				) {
					if (next === 'payment' || next === 'optom' || next === 'preview' || next === 'print') {
						if (
							salesOrderInfo.data.apiData.is_approval_needed &&
							!salesOrderInfo.data.apiData.is_approval_verified
						) {
							newState.showModalApproval = true;
						} else {
							newState.next = null;
							newState.showModalException = true;
						}
					} else if (next) {
						newState.next = null;
						this.props.history.push(next);
					}
				} else if (
					salesOrderInfo.data &&
					salesOrderInfo.data.apiData &&
					salesOrderInfo.data.apiData.payment_exceptions &&
					salesOrderInfo.data.apiData.payment_exceptions.length > 0
				) {
					if (next === 'optom' || next === 'preview' || next === 'print') {
						
						if (
							salesOrderInfo.data.apiData.is_approval_needed &&
							!salesOrderInfo.data.apiData.is_approval_verified
						) {
							newState.showModalApproval = true;
						} else {
							newState.next = null;
							newState.showModalException = true;
						}
					} else if (next) {
						newState.next = null;
						this.props.history.push(next);
					}
				} else {
					if (next) {
						if (next === 'preview') {
							this.previewSalesOrder();
						}
						newState.next = null;
						newState.showModalException = false;
						this.props.history.push(next);
					}
				}
			}
		}

		if (approvalInfo && approvalInfo !== prevApprovalInfo) {
			switch (approvalInfo.type) {
			case cons.POST_APPROVAL.SUCCESS:
				this.setIsVerified(approvalInfo.data.is_verfied);
				break;
			case cons.POST_APPROVAL.FAILURE:
				this.setIsVerified(false);
				break;
			}
		}

		if (salesOrderConfirmInfo && salesOrderConfirmInfo !== prevSalesOrderConfirmInfo) {
			if (salesOrderConfirmInfo.type === cons.CONFIRM_SALESORDER.SUCCESS) {
				this.viewOrder();
				if (next) {
					newState.next = null;
					this.props.history.push(next);
				}
			} else if (salesOrderConfirmInfo.type === cons.CONFIRM_SALESORDER.FAILURE) {
				newState.next = null;
			}
		}

		if (salesOrderHoldInfo && salesOrderHoldInfo !== prevSalesOrderHoldInfo) {
			switch (salesOrderHoldInfo.type) {
			case cons.HOLD_SALESORDER.SUCCESS:
				alert(`${salesOrderHoldInfo.data.transaction_number} is saved`);
				break;
			case cons.HOLD_SALESORDER.FAILURE:
				if (salesOrderHoldInfo.error.code) {
					alert(loc[salesOrderHoldInfo.error.code]);
				}
				break;
			}
		}

		this.setState(newState);
	}

	isLoading() {
		const { salesOrderInfo, salesOrderConfirmInfo, previewOrderInfo, viewOrderInfo, setItemToCurrentShopInfo, unsetItemFromCurrentShopInfo } = this.props;
		return salesOrderInfo.isFetching || salesOrderConfirmInfo.isFetching || previewOrderInfo.isFetching || viewOrderInfo.isFetching || setItemToCurrentShopInfo.isFetching || unsetItemFromCurrentShopInfo.isFetching;
	}

	isDisabled() {
		let ret = this.isLoading();
		if (ret) {
			return ret;
		}
		const { salesOrder } = this.state;
		return !salesOrder.get('salesman_code1') || !salesOrder.get('salesman_code2') || !salesOrder.get('optometrist_code');
	}

	setIsVerified(isVerified) {
		const { approvalUserId, approvalRemark, salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set('is_approval_verified', isVerified)
				.set('approval_user_code', approvalUserId)
				.set('approval_remark', approvalRemark)
			,
			showModalApproval: !isVerified,
		}, isVerified ? this.saveSalesOrder : undefined);
	}

	allowHoldOrder() {
		const { salesOrderInfo } = this.props;
		const apiData = salesOrderInfo && salesOrderInfo.data && salesOrderInfo.data.apiData;
		return (
			apiData &&
			apiData.transaction_items.length > 0 &&
			apiData.salesman_exceptions.length === 0 &&
			!apiData.q_transaction_number &&
			!apiData.return_transaction_number &&
			this.getPage() !== 'print'
		);
	}

	getSalesOrder(salesOrderApiData) {
		let { salesOrder } = this.state;
		salesOrder = salesOrder.merge(salesOrderApiData);
		salesOrder = salesOrder
			.set('doc_type', salesOrder.get('doc_type') ? salesOrder.get('doc_type').trim() : null)
			.set(
				'transaction_items',
				new List(
					salesOrder.get('transaction_items').map((transactionItem) => new Map(transactionItem))
				)
			)
			.set(
				'transaction_payments',
				new List(
					salesOrder.get('transaction_payments').map((transactionPayment) => new Map(transactionPayment))
				)
			)
			.set(
				'coupon_codes',
				new List(
					salesOrder.get('coupon_codes')
				)
			)
			.set(
				'promotion_ids_selected',
				new List(
					salesOrder.get('promotion_ids_selected')
				)
			)
		;
		if (salesOrder.get('special_options')) {
			let specialOptions = {};
			salesOrder.get('special_options').forEach((specialOption) => {
				specialOptions[specialOption.name] = specialOption.value;
			});
			salesOrder = salesOrder.set('special_options', new Map(specialOptions));
		}
		return salesOrder;
	}

	getPage(props) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.page;
	}

	getSalesOrderApiData() {
		const { salesOrder } = this.state;
		let ret = salesOrder.toJS();

		// Handle payment
		ret.transaction_payments = ret.transaction_payments.map((transactionPayment) => ({
			...transactionPayment,
			amount: parseFloat(transactionPayment.amount) || 0,
		}));

		// Handle special options
		let special_options = [];
		for (let i in ret.special_options) {
			special_options.push({
				name: i,
				value: ret.special_options[i],
			});
		}
		ret.special_options = special_options;

		ret.tracking = {
			type: 'web',
			uuid: uuidv4(),
			deviceUuid: new DeviceUUID().get(),
			userAgent: getUserAgent(),
		};

		return ret;
	}

	saveSalesOrder() {
		const { salesOrder } = this.state;
		if (this.getPage() !== 'salesman' && !salesOrder.get('doc_type')) {
			alert(loc.pleaseSelectQROrder);
			this.setState({ next: null });
			return;
		}

		let param = {
			payload: {
				apiData: this.getSalesOrderApiData(),
			},
		};
		param.payload.apiData.step = this.getPage();
		this.props.updateSalesOrder(param);
	}

	resetSalesOrder() {
		this.setState({
			...this.defaultState,
		}, () => {
			let param = {
				payload: {
					apiData: this.getSalesOrderApiData(),
				},
				reset: true,
			};
			param.payload.apiData.step = 'salesman';
			this.props.updateSalesOrder(param);
			this.props.history.push('salesman');
		});
	}

	holdSalesOrder() {
		const param = {
			payload: {
				apiData: this.getSalesOrderApiData(),
			},
		};
		this.props.holdSalesOrder(param);
	}

	previewSalesOrder() {
		const { signature } = this.state;
		const param = {
			payload: {
				apiData: {
					...this.getSalesOrderApiData(),
					signature,
					step: this.getPage(),
				}
			},
		};
		this.props.previewOrder(param);
	}

	confirmSalesOrder() {
		const param = {
			payload: {
				apiData: this.getSalesOrderApiData(),
			},
		};
		this.props.confirmSalesOrder(param);
	}

	viewOrder() {
		const { salesOrderConfirmInfo } = this.props;
		const { signature } = this.state;
		if (
			salesOrderConfirmInfo &&
			salesOrderConfirmInfo.data &&
			salesOrderConfirmInfo.data.apiData &&
			salesOrderConfirmInfo.data.apiData.transaction_number
		) {
			const param = {
				payload: {
					trx_no: salesOrderConfirmInfo.data.apiData.transaction_number,
					signature,
				},
			};
			this.props.viewOrder(param);
		}
	}

	onStepClickHandler(step) {
		let handler;
		switch (step) {
		case 'salesman':
		case 'cart':
		case 'payment':
		case 'optom':
		case 'preview':
			handler = this.saveSalesOrder;
			break;
		case 'print':
			handler = this.confirmSalesOrder;
			break;
		}
		this.setState({
			next: step,
		}, handler);
	}

	onSelectSalesmanHandler(salesmanType, salesman) {
		const { salesOrder } = this.state;
		switch (salesmanType) {
		case 'salesman1':
			this.setState({
				salesOrder: salesOrder
					.set('salesman_code1', salesman ? salesman.user_id : null)
					.set('salesman_name1', salesman ? salesman.user_name : null)
				,
			});
			break;
		case 'salesman2':
			this.setState({
				salesOrder: salesOrder
					.set('salesman_code2', salesman ? salesman.user_id : null)
					.set('salesman_name2', salesman ? salesman.user_name : null)
				,
			});
			break;
		case 'optometrist':
			this.setState({
				salesOrder: salesOrder
					.set('optometrist_code', salesman ? salesman.user_id : null)
					.set('optometrist_name', salesman ? salesman.user_name : null)
				,
			});
			break;
		}
	}

	onSelectMemberHandler(member) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set('member_code', member.member_code)
				.set('member', member)
			,
		});
	}

	onChangeQROrderHandler(type) {
		let { salesOrder } = this.state;
		if (salesOrder.get('q_transaction_number') || salesOrder.get('return_transaction_number')) {
			type = 'r';
		}
		switch (type) {
		case 'q':
			salesOrder = salesOrder.set('doc_type', 'DP1');
			break;
		case 'r':
			salesOrder = salesOrder.set('doc_type', 'SA1');
			break;
		default:
			salesOrder = salesOrder.remove('doc_type');
			break;
		}
		this.setState({
			salesOrder,
		}, this.saveSalesOrder);
	}

	onChangeRemarkHandler(remark) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder.set('remark', remark),
		});
	}

	onSelectLotNoHandler(index, lotNo) {
		const { salesOrder } = this.state;
		const transactionItem = salesOrder.get('transaction_items').get(index);
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_items',
					salesOrder.get('transaction_items').set(
						index,
						transactionItem.set('lot_no', lotNo.lot_no)
					)
				)
			,
		}, this.saveSalesOrder);
	}

	onApplyPromotionsHandler(promotionIdsSelected) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'promotion_ids_selected',
					new List(promotionIdsSelected)
				)
			,
		}, this.saveSalesOrder);
	}

	onAddTransactionItemHandler(transactionItem) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_items',
					salesOrder.get('transaction_items').push(transactionItem)
				)
			,
		}, this.saveSalesOrder);
	}

	onAddTransactionItemsHandler(transactionItems) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_items',
					salesOrder.get('transaction_items').concat(transactionItems)
				)
			,
		}, this.saveSalesOrder);
	}

	onChangeTransactionItemHandler(index, data) {
		const { salesOrder } = this.state;
		const transactionItem = salesOrder.get('transaction_items').get(index);
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_items',
					salesOrder.get('transaction_items').set(
						index,
						transactionItem
							.set('item_quantity', data.item_quantity)
							.set('discount_percentage', data.discount_percentage)
							.set('discount_reduction', data.discount_reduction)
							.set('discount_amount', data.discount_amount)
					)
				)
			,
		}, this.saveSalesOrder);
	}

	onRemoveTransactionItemHandler(index) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_items',
					salesOrder.get('transaction_items').delete(index)
				)
			,
		}, this.saveSalesOrder);
	}

	onRemoveTransactionItemsHandler(indices, callback = null) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_items',
					salesOrder.get('transaction_items').filter((transactionItem, index) => (
						indices.indexOf(index) === -1
					))
				)
			,
		}, callback || this.saveSalesOrder);
	}

	onAddCouponCodeHandler(couponCode) {
		const { salesOrder } = this.state;
		couponCode = couponCode.toUpperCase();
		this.setState({
			salesOrder: salesOrder
				.set(
					'coupon_codes',
					salesOrder.get('coupon_codes').push(couponCode),
				)
			,
		}, this.saveSalesOrder);
	}

	onChangeCouponCodeHandler(couponCode, data) {
		if (data.quantity <= 0) {
			return this.onRemoveCouponCodeHandler(couponCode);
		}

		const { salesOrder } = this.state;
		let couponCodeMap = {};
		salesOrder.get('coupon_codes').forEach((couponCode) => {
			if (!couponCodeMap[couponCode]) {
				couponCodeMap[couponCode] = {
					couponCode,
					quantity: 0,
				};
			}
			couponCodeMap[couponCode].quantity++;
		});
		couponCodeMap[couponCode].quantity = data.quantity;

		let tmp = Object.values(couponCodeMap).sort((a, b) => a.couponCode.localeCompare(b.couponCode));
		let couponCodes = [];
		for (let i = 0; i < tmp.length; i++) {
			for (let j = 0; j < tmp[i].quantity; j++) {
				couponCodes.push(tmp[i].couponCode);
			}
		}

		this.setState({
			salesOrder: salesOrder
				.set(
					'coupon_codes',
					new List(couponCodes)
				)
			,
		}, this.saveSalesOrder);
	}

	onRemoveCouponCodeHandler(couponCode) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'coupon_codes',
					salesOrder.get('coupon_codes').filterNot((c) => c === couponCode)
				)
			,
		}, this.saveSalesOrder);
	}

	onChangeDepositAmountHandler(depositAmount) {
		const { salesOrder } = this.state;
		if (depositAmount > salesOrder.get('net_amount')) {
			alert(loc.TRANS0040);
		} else if (salesOrder.get('deposit_amount') != depositAmount) {
			this.setState({
				salesOrder: salesOrder.set('deposit_amount', depositAmount)
			}, this.saveSalesOrder);
		}
	}

	onAddTransactionPaymentHandler(transactionPayment) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_payments',
					salesOrder.get('transaction_payments').push(transactionPayment)
				)
			,
		}, this.saveSalesOrder);
	}

	onChangeTransactionPaymentHandler(index, data) {
		const { salesOrder } = this.state;
		const transactionPayment = salesOrder.get('transaction_payments').get(index);
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_payments',
					salesOrder.get('transaction_payments').set(
						index,
						transactionPayment
							.set('amount', data.amount)
					)
				)
			,
		}, this.saveSalesOrder);
	}

	onRemoveTransactionPaymentHandler(index) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder
				.set(
					'transaction_payments',
					salesOrder.get('transaction_payments').delete(index)
				)
			,
		}, this.saveSalesOrder);
	}

	onChangeSignatureHandler(signature) {
		const page = this.getPage();
		this.setState({
			signature,
		}, page === 'preview' ? this.previewSalesOrder : this.viewOrder);
	}

	onPrintHandler() {
		const { salesOrderConfirmInfo } = this.props;
		const { signature } = this.state;
		if (
			salesOrderConfirmInfo &&
			salesOrderConfirmInfo.data &&
			salesOrderConfirmInfo.data.apiData &&
			salesOrderConfirmInfo.data.apiData.transaction_number
		) {
			const param = {
				payload: {
					trx_no: salesOrderConfirmInfo.data.apiData.transaction_number,
					signature,
				},
			};
			this.props.printOrder(param);
		}
	}

	onToggleModalPromotionsHandler(tab) {
		const { showModalPromotions } = this.state;
		this.setState({
			showModalPromotions: showModalPromotions ? null : tab,
		});
	}

	onToggleModalExceptionHandler() {
		const { showModalException } = this.state;
		this.setState({
			showModalException: !showModalException,
		});
	}

	onToggleModalApprovalHandler() {
		this.setState({
			showModalApproval: false,
		});
	}

	onRequestApprovalHandler({ user_id, code, remark }) {
		const param = {
			payload: {
				user_id,
				code,
			},
		};
		this.setState({
			approvalUserId: user_id,
			approvalRemark: remark,
		});
		this.props.postApproval(param);
	}

	onSelectOptomRecordHandler(rftNo) {
		const { salesOrder } = this.state;
		this.setState({
			salesOrder: salesOrder.set('rft_no', salesOrder.get('rft_no') === rftNo ? null : rftNo),
		}, this.saveSalesOrder);
	}

	onToggleModalSpecialOptionsHandler() {
		const { showModalSpecialOptions } = this.state;
		this.setState({
			showModalSpecialOptions: !showModalSpecialOptions,
		});
	}

	onChangeSpecialOptionsHandler(name, value) {
		const { salesOrder } = this.state;
		const specialOptions = salesOrder.get('special_options');
		this.setState({
			salesOrder: salesOrder.set('special_options', specialOptions.set(name, value)),
		});
	}

	render() {
		const { salesOrder, signature, showModalPromotions, showModalException, showModalApproval, showModalSpecialOptions } = this.state;
		const { salesOrderInfo, history } = this.props;
		const isDisabled = this.isDisabled();
		const isLoading = this.isLoading();
		const commonProps = {
			salesOrder,
			type: 'salesOrder',
			isDisabled,
			isLoading,
			onStepClick: this.onStepClickHandler,
			onToggleModalSpecialOptions: this.onToggleModalSpecialOptionsHandler,
		};
		const page = this.getPage();
		let body = null;
		switch (page) {
		case 'salesman':
			body = (
				<PageSalesOrderSalesman
					next={ this.progressBar[1] }
					onSelectSalesman={ this.onSelectSalesmanHandler }
					onSelectMember={ this.onSelectMemberHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'cart':
			body = (
				<PageSalesOrderCart
					next={ this.progressBar[2] }
					onChangeQROrder={ this.onChangeQROrderHandler }
					onChangeRemark={ this.onChangeRemarkHandler }
					onSelectLotNo={ this.onSelectLotNoHandler }
					onToggleModalPromotions={ this.onToggleModalPromotionsHandler }
					onAddTransactionItem={ this.onAddTransactionItemHandler }
					onAddTransactionItems={ this.onAddTransactionItemsHandler }
					onChangeTransactionItem={ this.onChangeTransactionItemHandler }
					onRemoveTransactionItem={ this.onRemoveTransactionItemHandler }
					onRemoveTransactionItems={ this.onRemoveTransactionItemsHandler }
					onAddCouponCode={ this.onAddCouponCodeHandler }
					onChangeCouponCode={ this.onChangeCouponCodeHandler }
					onRemoveCouponCode={ this.onRemoveCouponCodeHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'payment':
			body = (
				<PageSalesOrderPayment
					next={ this.progressBar[3] }
					onChangeDepositAmount={ this.onChangeDepositAmountHandler }
					onAddTransactionPayment={ this.onAddTransactionPaymentHandler }
					onChangeTransactionPayment={ this.onChangeTransactionPaymentHandler }
					onRemoveTransactionPayment={ this.onRemoveTransactionPaymentHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'optom':
			body = (
				<PageSalesOrderOptom
					next={ this.progressBar[4] }
					onSelectOptomRecord={ this.onSelectOptomRecordHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'preview':
		case 'print':
			body = (
				<PageSalesOrderPrint
					preview={ page === 'preview' }
					next={ page === 'preview' ? this.progressBar[5] : null }
					signature={ signature }
					onChangeSignature={ this.onChangeSignatureHandler }
					onPrint={ this.onPrintHandler }
					onReset={ this.resetSalesOrder }
					{ ...commonProps }
				/>
			);
			break;
		}

		return (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<ProgressBar
						name="salesOrder"
						page={ page }
						items={ this.progressBar }
						salesOrder={ salesOrder }
						history={ history }
						onStepClick={ this.onStepClickHandler }
						onReset={ this.resetSalesOrder }
						onHold={ this.allowHoldOrder() ? this.holdSalesOrder : undefined }
						onToggleModalPromotions={ this.onToggleModalPromotionsHandler }
					/>
					<div>
						{ body }
					</div>
				</div>

				<ModalPromotions
					isOpen={ showModalPromotions }
					isLoading={ isLoading }
					type="salesOrder"
					current={ page }
					onToggle={ this.onToggleModalPromotionsHandler }
					onApply={ this.onApplyPromotionsHandler }
					salesOrder={ salesOrder }
				/>

				<ModalApproval
					isOpen={ showModalApproval }
					onToggle={ this.onToggleModalApprovalHandler }
					onSubmit={ this.onRequestApprovalHandler }
				/>

				<ModalException
					page={ page }
					isOpen={ showModalException }
					onToggle={ this.onToggleModalExceptionHandler }
					data={ salesOrderInfo }
				/>

				<ModalSpecialOptions
					isOpen={ showModalSpecialOptions }
					onToggle={ this.onToggleModalSpecialOptionsHandler }
					onChange={ this.onChangeSpecialOptionsHandler }
					salesOrder={ salesOrder }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		defaultSalesOrderData: state.defaultSalesOrderData,
		salesOrderInfo: state.salesOrderInfo,
		salesOrderConfirmInfo: state.salesOrderConfirmInfo,
		salesOrderHoldInfo: state.salesOrderHoldInfo,
		retrieveHoldSalesOrderInfo: state.retrieveHoldSalesOrderInfo,
		currentInfo: state.currentInfo,
		previewOrderInfo: state.previewOrderInfo,
		viewOrderInfo: state.viewOrderInfo,
		approvalInfo: state.approvalInfo,
		setItemToCurrentShopInfo: state.setItemToCurrentShopInfo,
		unsetItemFromCurrentShopInfo: state.unsetItemFromCurrentShopInfo,
	}),
	(dispatch) => ({
		setDefaultSalesOrderData: para => dispatch(setDefaultSalesOrderData(para)),
		updateSalesOrder: para => dispatch(updateSalesOrder(para)),
		postApproval: (para) => dispatch(postApproval(para)),
		confirmSalesOrder: para => dispatch(confirmSalesOrder(para)),
		holdSalesOrder: para => dispatch(holdSalesOrder(para)),
		previewOrder: para => dispatch(previewOrder(para)),
		viewOrder: para => dispatch(viewOrder(para)),
		printOrder: para => dispatch(printOrder(para)),
	})
)(PageSalesOrder);
